<script lang="ts" setup>
import type { TJob } from '@tl-ui/components/job/JobCard.vue'
import { setErrors, clearErrors } from '@formkit/core'
import type { FormKitFrameworkContext, FormKitGroupValue } from '@formkit/core'
const { $sentryCaptureException, $setGAUser, $setClarityUser, $setFBQUser, $fbqTrack } =
  useNuxtApp()
const { t } = useI18n()
const { loginWithOTP, loginWithPassword, signup, requestOTP } = useAuthFetch()
const viewport = useViewport()
const isMobile = computed(() => viewport.isLessThan('md'))
const { queryUser } = useUserManage()
const { saveJob } = useJob()
const { gtag } = useGtag()

const props = defineProps({
  show: Boolean,
  job: {
    type: Object as PropType<TJob>,
    required: true
  }
})

let autoFocusTimeOut: ReturnType<typeof setTimeout>

type stage = 'SELECT_USER' | 'LOGIN_PW' | 'LOGIN_OTP' | 'WITHOUT_AUTH'
const currentStage = ref<stage>('SELECT_USER')
const submitBtnText = computed(() => {
  if (currentStage.value === 'SELECT_USER') {
    return t('jobBoard.jobPage.continueToSave')
  }
  if (currentStage.value === 'LOGIN_PW') {
    return t('jobBoard.jobPage.save')
  }
  if (currentStage.value === 'LOGIN_OTP') {
    return t('jobBoard.jobPage.save')
  }
  return t('jobBoard.jobPage.save')
})

function autoFocus(fieldName: string) {
  autoFocusTimeOut = setTimeout(() => {
    if (!form.value?.$el) return
    const elm = form.value.$el.querySelector(`input[name="${fieldName}"]`)
    if (elm instanceof HTMLInputElement) {
      elm.focus()
    }
  }, 200)
}

const formId = `apply-job-form-${props.job.idSlug}`
const otpLoading = ref(false)
const otpCountDown = ref(-1)
const countDownInterval = ref<NodeJS.Timeout | null>(null)
const form = ref<{ $el: HTMLElement } | null>(null)
const formValues = ref<{
  email?: string
  password?: string
  phone?: {
    value: string
    isValid: boolean
  }
  firstName?: string
  lastName?: string
  resume?: File
}>({
  email: undefined,
  password: undefined,
  firstName: undefined,
  lastName: undefined,
  phone: undefined,
  resume: undefined
})

const emit = defineEmits(['update:show', 'update:save'])

function reset() {
  currentStage.value = 'SELECT_USER'
}

reset()

function handleShow(show: boolean) {
  if (!show) {
    reset()
    if (countDownInterval.value) clearInterval(countDownInterval.value)
    if (autoFocusTimeOut) clearTimeout(autoFocusTimeOut)
  } else {
    autoFocus('email')
    reset()
  }
  emit('update:show', show)
}

onUnmounted(() => {
  if (countDownInterval.value) clearInterval(countDownInterval.value)
  if (autoFocusTimeOut) clearTimeout(autoFocusTimeOut)
})

async function handleRequestOTP() {
  currentStage.value = 'LOGIN_OTP'
  if (!formValues.value.email) return
  try {
    otpLoading.value = true
    otpCountDown.value = 60
    await requestOTP(formValues.value.email)
    if (countDownInterval.value) clearInterval(countDownInterval.value)
    otpLoading.value = false
    countDownInterval.value = setInterval(() => {
      otpCountDown.value--
      if (otpCountDown.value <= 0 && countDownInterval.value) {
        otpCountDown.value = -1
        clearInterval(countDownInterval.value)
      }
    }, 1000)
    autoFocus('password')
  } catch (error) {
    otpLoading.value = false
    otpCountDown.value = -1
    setErrors(formId, ['something-went-wrong'])
    $sentryCaptureException(error)
  }
}

async function handleSearchUser() {
  if (!formValues.value.email) return
  try {
    const res = await queryUser(formValues.value.email)
    formValues.value = {
      ...formValues.value,
      password: undefined,
      firstName: undefined,
      lastName: undefined,
      phone: undefined,
      resume: undefined
    }
    if (res.exist) {
      // If the email is already registered, we will ask for password/login
      currentStage.value = 'LOGIN_PW'
      autoFocus('password')
    } else {
      // If the email is not registered, we will ask for name, phone, resume for application directly without login
      currentStage.value = 'WITHOUT_AUTH'
      autoFocus('firstName')
    }
  } catch (error) {
    console.error(error)
    handleFetchError(error, {
      handleUnknownError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      },
      handle401Error: () => {
        setErrors(formId, ['invalid-email-or-password'])
      },
      handle429Error: () => {
        setErrors(formId, ['too-many-requests'])
      },
      handle500OrAboveError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      }
    })
  }
}

async function handleOTPLogin() {
  if (!formValues.value.email || !formValues.value.password) return
  try {
    const response = await loginWithOTP(formValues.value.email, formValues.value.password)
    const { rawToken } = useAuthState()
    const { getSession } = useAuth()
    rawToken.value = response.access

    await nextTick(getSession)

    // emit GA login event
    const { data: user } = useAuth()
    $setGAUser(user.value)
    $setClarityUser(user.value)
    $setFBQUser(user.value)
    gtag('event', 'login', {
      method: 'OTP',
      location: 'Job Save Modal',
      account_type: getPrimaryUserRole(user.value)
    })

    await handleSaveJob()
  } catch (error) {
    handleFetchError(error, {
      handleUnknownError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      },
      handle401Error: () => {
        setErrors(formId, ['invalid-email-or-password'])
      },
      handle403Error: () => {
        setErrors(formId, ['invalid-email-or-password'])
      },
      handle500OrAboveError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      }
    })
  }
}

async function handleLogin() {
  if (!formValues.value.email || !formValues.value.password) return
  try {
    const response = await loginWithPassword(formValues.value.email, formValues.value.password)
    const { rawToken } = useAuthState()
    const { getSession } = useAuth()
    rawToken.value = response.access

    await nextTick(getSession)

    // emit GA login event
    const { data: user } = useAuth()
    $setGAUser(user.value)
    $setClarityUser(user.value)
    $setFBQUser(user.value)
    gtag('event', 'login', {
      method: 'Password',
      location: 'Job Save Modal',
      account_type: getPrimaryUserRole(user.value)
    })

    await handleSaveJob()
  } catch (error) {
    handleFetchError(error, {
      handleUnknownError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      },
      handle401Error: () => {
        setErrors(formId, ['invalid-email-or-password'])
      },
      handle500OrAboveError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      }
    })
  }
}

async function handleSaveJob() {
  if (!formValues.value.email || !props.job.idSlug) return

  // If unauth, create a user account first
  if (currentStage.value === 'WITHOUT_AUTH') {
    if (!formValues.value.firstName || !formValues.value.lastName) return
    try {
      // Create a user account with random password
      // Signup
      const password = generatePassword()
      await signup({
        email: formValues.value.email,
        password,
        first_name: formValues.value.firstName,
        last_name: formValues.value.lastName,
        phone: formValues.value.phone?.value,
        user_roles: ['STUDENT'],
        anonymous_signup_stage: 'SELF_EXPRESS_SIGNUP'
      })

      // Login
      const response = await loginWithPassword(formValues.value.email, password)
      const { rawToken } = useAuthState()
      const { getSession } = useAuth()
      rawToken.value = response.access
      await nextTick(getSession)
      const { data: user } = useAuth()
      // emit GA login event
      $setGAUser(user.value)
      $setClarityUser(user.value)
      $setFBQUser(user.value)

      gtag('event', 'sign_up', {
        method: 'Password',
        lead_source: 'Self signup',
        location: 'Job Save Modal',
        account_type: 'candidate'
      })
      gtag('event', 'login', {
        method: 'Password',
        location: 'Job Save Modal',
        account_type: 'candidate'
      })
      $fbqTrack('CompleteRegistration', {
        content_name: props.job.title,
        content_category: 'JobPost',
        // @ts-ignore
        content_ids: [props.job.idSlug],
        delivery_category: 'SaveJobModal'
      })
    } catch (error) {
      setErrors(formId, ['something-went-wrong'])
      $sentryCaptureException(error)
    }
  }

  try {
    // Save job
    const saveRes = await saveJob(props.job.idSlug as string)
    if (!saveRes) throw new Error('saveRes is undefined')
    emit('update:save', saveRes.id_slug)
    emit('update:show', false)
  } catch (error) {
    setErrors(formId, ['something-went-wrong'])
    $sentryCaptureException(error)
  }
}

function resetError() {
  clearErrors(formId)
}

async function handleSubmit() {
  resetError()
  if (currentStage.value === 'SELECT_USER') {
    return await handleSearchUser()
  }
  if (currentStage.value === 'LOGIN_PW') {
    return await handleLogin()
  }
  if (currentStage.value === 'LOGIN_OTP') {
    return await handleOTPLogin()
  }
  return await handleSaveJob()
}
</script>

<template>
  <Modal
    :show="props.show"
    :width-override="isMobile ? undefined : '45rem'"
    :close-on-overlay-click="false"
    @update:show="handleShow"
  >
    <div class="max-h-[80vh] overflow-y-auto px-[1.88rem] pt-0 md:px-[3.12rem]">
      <div class="w-full border-b-[1px] border-gray-30">
        <div v-if="isMobile" class="pb-[1.88rem] text-h4 font-medium">{{ job.title }}</div>
        <JobCard
          v-else
          :job="job"
          :redirect="false"
          border-radius-override="0"
          border-left="0"
          border-right="0"
          border-top="0"
          border-bottom="0"
        >
          <template v-if="job?.logo?.url && job.company" #company-logo>
            <JobCardLogo :logo="job.logo" :company="job.company" />
          </template>
        </JobCard>
      </div>
      <FormKit
        :id="formId"
        ref="form"
        v-model="formValues"
        type="form"
        :actions="false"
        @update:model-value="resetError"
        @submit="handleSubmit"
      >
        <div class="flex flex-col gap-[1.88rem] pt-[1.88rem]">
          <FormKitInput
            type="email"
            name="email"
            autocomplete="email,username"
            :disabled="currentStage !== 'SELECT_USER'"
            :label="$t('common.auth.email')"
            :placeholder="$t('common.auth.email')"
            validation="required|email"
            validation-visibility="dirty"
          />
          <div v-if="currentStage === 'LOGIN_PW'" class="flex flex-col gap-[0.88rem]">
            <FormKitInput
              type="password"
              name="password"
              :label="$t('common.auth.password')"
              :placeholder="$t('common.auth.password')"
              validation="required|length:6"
              validation-visibility="dirty"
              autocomplete="current-password"
              @keyup.enter="$formkit.submit(formId)"
            />
            <div class="flex flex-row justify-start font-light">
              <div>
                {{ $t('common.auth.forgotPassword') }}
                <a
                  type="button"
                  class="cursor-pointer text-blue-40 hover:underline"
                  @click="
                    () => {
                      currentStage = 'LOGIN_OTP'
                      autoFocus('password')
                    }
                  "
                >
                  {{ $t('common.auth.loginWithOTP') }}
                </a>
              </div>
            </div>
          </div>

          <div v-if="currentStage === 'LOGIN_OTP'" class="flex flex-col gap-[0.88rem]">
            <div class="flex flex-row gap-[0.88rem]">
              <FormKitInput
                type="password"
                name="password"
                :label="$t('common.auth.OTP')"
                :placeholder="$t('common.auth.OTP')"
                validation="required"
                validation-visibility="dirty"
                @keyup.enter="$formkit.submit(formId)"
              />
              <div class="mt-[40px]">
                <ButtonBlackButton
                  :text="otpCountDown > 0 ? `${otpCountDown}` : $t('common.form.send')"
                  :loading="otpLoading || otpCountDown > 0"
                  :height-override="'2.75rem'"
                  @click="handleRequestOTP"
                />
              </div>
            </div>
          </div>

          <div v-if="currentStage === 'WITHOUT_AUTH'" class="flex flex-row gap-[1.25rem]">
            <FormKitInput
              type="text"
              name="firstName"
              :label="$t('common.auth.firstName')"
              :placeholder="$t('common.auth.firstName')"
              validation="required"
              validation-visibility="dirty"
            />
            <FormKitInput
              type="text"
              name="lastName"
              :label="$t('common.auth.lastName')"
              :placeholder="$t('common.auth.lastName')"
              validation="required"
              validation-visibility="dirty"
            />
          </div>
          <FormKitInput
            v-if="currentStage === 'WITHOUT_AUTH'"
            type="tlPhone"
            name="phone"
            :label="$t('common.auth.phone')"
            validation="tlphone"
            validation-visibility="dirty"
            :enter-key-up-handler="() => $formkit.submit(formId)"
          />
        </div>
        <template #messages="context: FormKitFrameworkContext<FormKitGroupValue | undefined>">
          <div
            class="mt-[1.25rem] flex flex-row items-center gap-[1.25rem] rounded-card text-t6 font-light text-red-40 transition-[height] duration-150"
            :class="{
              'h-0 overflow-hidden p-0': errorMsgObjToArr($t, context.messages).length === 0,
              'ring-0': errorMsgObjToArr($t, context.messages).length > 0
            }"
          >
            <ul>
              <li v-for="(message, idx) in errorMsgObjToArr($t, context.messages)" :key="idx">
                {{ message }}
              </li>
            </ul>
          </div></template
        >
        <template #actions="context">
          <div class="mt-[1.25rem] flex flex-row justify-end">
            <ButtonBlackButton
              :loading="Boolean(context?.node?.store.loading?.value)"
              :text="submitBtnText"
              @click="$formkit.submit(formId)"
            ></ButtonBlackButton>
          </div>
        </template>
      </FormKit>
    </div>
  </Modal>
</template>
